@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
* {
    font-family: 'Source Sans 3';
    margin: 0;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
    --azul-oscuro: #313e48;
    --azul: #238dc0;
    --blanco: #fff;
    --site_width: 1400px;
}

header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

header img {
    width: 200px;
}

.login {
    gap: 20px;
    height: 70vh;
    max-width: var(--site_width);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login form {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    padding: 30px;
    border-radius: 5px;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 40px;
}

.intro-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    margin-bottom: 20px;
}

h1 {
    color: var(--azul-oscuro);
    font-weight: 900;
    font-size: 25px;
}

h2 {
    color: var(--azul-oscuro);
    font-size: 23px;
}

h3 {
    color: var(--azul-oscuro);
}

p {
    font-size: 15px;
    color: #878787;
}

.intro-login p {
    color: #878787;
}

.input-data {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.login form button {
    background-color: var(--azul);
    color: var(--blanco);
    border: 0px;
    padding: 7px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
}

.input-data label {
    color: var(--azul) !important;
    font-weight: 500;
    font-size: 15px;
}

.input-data input {
    border: 1px solid #0000001b;
    padding: 5px;
}

.generador-container {
    max-width: var(--site_width);
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 30px;
}

.secciones-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.secciones-container h2>span {
    color: var(--azul);
}

.grid-secciones {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.grid-secciones>div {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    padding: 20px;
    border-radius: 5px;
}

.intro-seccion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: fit-content;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    margin: auto;
    padding: 30px;
    gap: 15px;
    border-radius: 5px;
}

.intro-seccion img {
    width: 40px;
}

.intro-seccion>label {
    display: flex;
    flex-direction: column;
    gap: 17px;
    width: fit-content;
}

.intro-seccion>label select {
    border: 1px solid var(--azul);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.selector {
    margin-top: 50px;
}

.contenido-generador {
    display: grid;
    grid-template-columns: 0.6fr 0.4fr;
    gap: 45px;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.form-group input {
    padding: 5px;
    border: 0px;
    border-bottom: 1px solid var(--azul);
    outline: none;
    transition: all 0.3s !important;
}

.form-group input:focus {
    border-bottom: 1px solid var(--azul-oscuro);
}

.form-group p {
    color: var(--azul);
    font-weight: 500;
}

.form-seccion {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-seccion button {
    font-family: inherit;
    font-size: 13px;
    background: var(--azul-oscuro);
    color: white;
    display: flex;
    align-items: center;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border: none;
    overflow: hidden;
    transition: all 0.2s;
    cursor: pointer;
    width: fit-content;
    border-radius: 5px;
}

.form-seccion button span {
    display: block;
    margin-left: 0.3em;
    transition: all 0.3s ease-in-out;
}

.form-seccion button svg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
}

.form-seccion button:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
}

.form-seccion button:hover svg {
    transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.form-seccion button:hover span {
    transform: translateX(6em);
}

.form-seccion button:active {
    transform: scale(0.95);
}

@keyframes fly-1 {
    from {
        transform: translateY(0.1em);
    }
    to {
        transform: translateY(-0.1em);
    }
}

.datos-guardados {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border: 2px dashed rgb(230, 230, 230);
    border-radius: 5px;
    margin-top: 52px;
    height: fit-content;
    padding: 20px;
}

.nada-guardado {
    height: 200px !important;
    align-items: center !important;
    text-align: center;
}

.datos-guardados .lapiz {
    width: 30px;
}

.datos-guardados>div {
    width: 100%;
}

.datos-guardados h2 {
    color: #27ae60;
}

.datos-guardados h3 {
    color: var(--azul-oscuro);
    font-size: 16px;
    opacity: 0.8;
}

.datos-guardados textarea {
    width: 100%;
    resize: none;
    margin-top: 10px;
    border: 1px solid rgb(225, 225, 225);
    border-radius: 5px;
}

.datos-guardados button {
    background-color: var(--azul);
    color: var(--blanco);
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 500;
    border: 0px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}


/* Loader */

.loader-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70vh;
}


/* From Uiverse.io by NlghtM4re */

#loader {
    width: 80px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px -40px;
    z-index: 1000;
}

.lightsaber {
    position: absolute;
    width: 4px;
    height: 12px;
    background-color: #666;
    border-radius: 1px;
    bottom: 0;
}

.lightsaber.ls-left {
    left: 0;
}

.lightsaber.ls-right {
    right: 0;
}

.lightsaber:before {
    position: absolute;
    content: ' ';
    display: block;
    width: 2px;
    height: 25px;
    max-height: 1px;
    left: 1px;
    top: 1px;
    background-color: #fff;
    border-radius: 1px;
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
    -webkit-transform-origin: center top;
    -ms-transform-origin: center top;
    transform-origin: center top;
}

.lightsaber:after {
    position: absolute;
    content: ' ';
    display: block;
    width: 2px;
    height: 2px;
    left: 1px;
    top: 4px;
    background-color: #fff;
    border-radius: 50%;
}

.ls-particles {
    position: absolute;
    left: 42px;
    top: 10px;
    width: 1px;
    height: 5px;
    background-color: rgb(51, 51, 51, 0);
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
}

.lightsaber.ls-green:before {
    -webkit-animation: showlightgreen 2s ease-in-out infinite 1s;
    animation: showlightgreen 2s ease-in-out infinite 1s;
}

.lightsaber.ls-red:before {
    -webkit-animation: showlightred 2s ease-in-out infinite 1s;
    animation: showlightred 2s ease-in-out infinite 1s;
}

.lightsaber.ls-left {
    -webkit-animation: fightleft 2s ease-in-out infinite 1s;
    animation: fightleft 2s ease-in-out infinite 1s;
}

.lightsaber.ls-right {
    -webkit-animation: fightright 2s ease-in-out infinite 1s;
    animation: fightright 2s ease-in-out infinite 1s;
}

.ls-particles.ls-part-1 {
    -webkit-animation: particles1 2s ease-out infinite 1s;
    animation: particles1 2s ease-out infinite 1s;
}

.ls-particles.ls-part-2 {
    -webkit-animation: particles2 2s ease-out infinite 1s;
    animation: particles2 2s ease-out infinite 1s;
}

.ls-particles.ls-part-3 {
    -webkit-animation: particles3 2s ease-out infinite 1s;
    animation: particles3 2s ease-out infinite 1s;
}

.ls-particles.ls-part-4 {
    -webkit-animation: particles4 2s ease-out infinite 1s;
    animation: particles4 2s ease-out infinite 1s;
}

.ls-particles.ls-part-5 {
    -webkit-animation: particles5 2s ease-out infinite 1s;
    animation: particles5 2s ease-out infinite 1s;
}

@-webkit-keyframes showlightgreen {
    0% {
        max-height: 0;
        box-shadow: 0 0 0 0 var(--azul);
    }
    5% {
        box-shadow: 0 0 4px 2px var(--azul);
    }
    10% {
        max-height: 22px;
    }
    80% {
        max-height: 22px;
    }
    85% {
        box-shadow: 0 0 4px 2px var(--azul);
    }
    100% {
        max-height: 0;
        box-shadow: 0 0 0 0 var(--azul);
    }
}

@-webkit-keyframes showlightred {
    0% {
        max-height: 0;
        box-shadow: 0 0 0 0 #f06363;
    }
    20% {
        box-shadow: 0 0 4px 2px #f06363;
    }
    25% {
        max-height: 22px;
    }
    80% {
        max-height: 22px;
    }
    85% {
        box-shadow: 0 0 4px 2px #f06363;
    }
    100% {
        max-height: 0;
        box-shadow: 0 0 0 0 #f06363;
    }
}

@-webkit-keyframes fightleft {
    0% {
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
        left: 0;
        bottom: 0;
    }
    30% {
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
        bottom: 0;
    }
    40% {
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
        left: 0;
        bottom: 2px;
    }
    45% {
        -webkit-transform: rotateZ(65deg);
        transform: rotateZ(65deg);
        left: 0;
    }
    65% {
        -webkit-transform: rotateZ(410deg);
        transform: rotateZ(410deg);
        left: 30px;
        bottom: 10px;
    }
    95% {
        -webkit-transform: rotateZ(410deg);
        transform: rotateZ(410deg);
        left: 0;
        bottom: 0;
    }
    100% {
        -webkit-transform: rotateZ(360deg);
        transform: rotateZ(360deg);
        left: 0;
        bottom: 0;
    }
}

@-webkit-keyframes fightright {
    0% {
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
        right: 0;
        bottom: 0;
    }
    30% {
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
        bottom: 0;
    }
    45% {
        -webkit-transform: rotateZ(-45deg);
        transform: rotateZ(-45deg);
        right: 0;
        bottom: 2px;
    }
    50% {
        -webkit-transform: rotateZ(-65deg);
        transform: rotateZ(-65deg);
        right: 0;
    }
    68% {
        -webkit-transform: rotateZ(-410deg);
        transform: rotateZ(-410deg);
        right: 27px;
        bottom: 13px;
    }
    95% {
        -webkit-transform: rotateZ(-410deg);
        transform: rotateZ(-410deg);
        right: 0;
        bottom: 0;
    }
    100% {
        -webkit-transform: rotateZ(-360deg);
        transform: rotateZ(-360deg);
        right: 0;
        bottom: 0;
    }
}

@-webkit-keyframes particles1 {
    0% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(35deg) translateY(0px);
        transform: rotateZ(35deg) translateY(0px);
    }
    63% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(35deg) translateY(0px);
        transform: rotateZ(35deg) translateY(0px);
    }
    64% {
        background-color: rgba(51, 51, 51, 1);
        -webkit-transform: rotateZ(35deg) translateY(0px);
        transform: rotateZ(35deg) translateY(0px);
    }
    100% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(35deg) translateY(-30px);
        transform: rotateZ(35deg) translateY(-30px);
    }
}

@-webkit-keyframes particles2 {
    0% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-65deg) translateY(0px);
        transform: rotateZ(-65deg) translateY(0px);
    }
    63% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-65deg) translateY(0px);
        transform: rotateZ(-65deg) translateY(0px);
    }
    64% {
        background-color: rgba(51, 51, 51, 1);
        -webkit-transform: rotateZ(-65deg) translateY(0px);
        transform: rotateZ(-65deg) translateY(0px);
    }
    95% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-65deg) translateY(-40px);
        transform: rotateZ(-65deg) translateY(-40px);
    }
    100% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-65deg) translateY(-40px);
        transform: rotateZ(-65deg) translateY(-40px);
    }
}

@-webkit-keyframes particles3 {
    0% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-75deg) translateY(0px);
        transform: rotateZ(-75deg) translateY(0px);
    }
    63% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-75deg) translateY(0px);
        transform: rotateZ(-75deg) translateY(0px);
    }
    64% {
        background-color: rgba(51, 51, 51, 1);
        -webkit-transform: rotateZ(-75deg) translateY(0px);
        transform: rotateZ(-75deg) translateY(0px);
    }
    97% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-75deg) translateY(-35px);
        transform: rotateZ(-75deg) translateY(-35px);
    }
    100% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-75deg) translateY(-35px);
        transform: rotateZ(-75deg) translateY(-35px);
    }
}

@-webkit-keyframes particles4 {
    0% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-25deg) translateY(0px);
        transform: rotateZ(-25deg) translateY(0px);
    }
    63% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-25deg) translateY(0px);
        transform: rotateZ(-25deg) translateY(0px);
    }
    64% {
        background-color: rgba(51, 51, 51, 1);
        -webkit-transform: rotateZ(-25deg) translateY(0px);
        transform: rotateZ(-25deg) translateY(0px);
    }
    97% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-25deg) translateY(-30px);
        transform: rotateZ(-25deg) translateY(-30px);
    }
    100% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-25deg) translateY(-30px);
        transform: rotateZ(-25deg) translateY(-30px);
    }
}

@-webkit-keyframes particles5 {
    0% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(65deg) translateY(0px);
        transform: rotateZ(65deg) translateY(0px);
    }
    63% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(65deg) translateY(0px);
        transform: rotateZ(65deg) translateY(0px);
    }
    64% {
        background-color: rgba(51, 51, 51, 1);
        -webkit-transform: rotateZ(65deg) translateY(0px);
        transform: rotateZ(65deg) translateY(0px);
    }
    97% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(65deg) translateY(-35px);
        transform: rotateZ(65deg) translateY(-35px);
    }
    100% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(65deg) translateY(-35px);
        transform: rotateZ(65deg) translateY(-35px);
    }
}

@keyframes showlightgreen {
    0% {
        max-height: 0;
        box-shadow: 0 0 0 0 var(--azul);
    }
    5% {
        box-shadow: 0 0 4px 2px var(--azul);
    }
    10% {
        max-height: 22px;
    }
    80% {
        max-height: 22px;
    }
    85% {
        box-shadow: 0 0 4px 2px var(--azul);
    }
    100% {
        max-height: 0;
        box-shadow: 0 0 0 0 var(--azul);
    }
}

@keyframes showlightred {
    0% {
        max-height: 0;
        box-shadow: 0 0 0 0 #f06363;
    }
    20% {
        box-shadow: 0 0 4px 2px #f06363;
    }
    25% {
        max-height: 22px;
    }
    80% {
        max-height: 22px;
    }
    85% {
        box-shadow: 0 0 4px 2px #f06363;
    }
    100% {
        max-height: 0;
        box-shadow: 0 0 0 0 #f06363;
    }
}

@keyframes fightleft {
    0% {
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
        left: 0;
        bottom: 0;
    }
    30% {
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
        bottom: 0;
    }
    40% {
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
        left: 0;
        bottom: 2px;
    }
    45% {
        -webkit-transform: rotateZ(65deg);
        transform: rotateZ(65deg);
        left: 0;
    }
    65% {
        -webkit-transform: rotateZ(410deg);
        transform: rotateZ(410deg);
        left: 30px;
        bottom: 10px;
    }
    95% {
        -webkit-transform: rotateZ(410deg);
        transform: rotateZ(410deg);
        left: 0;
        bottom: 0;
    }
    100% {
        -webkit-transform: rotateZ(360deg);
        transform: rotateZ(360deg);
        left: 0;
        bottom: 0;
    }
}

@keyframes fightright {
    0% {
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
        right: 0;
        bottom: 0;
    }
    30% {
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
        bottom: 0;
    }
    45% {
        -webkit-transform: rotateZ(-45deg);
        transform: rotateZ(-45deg);
        right: 0;
        bottom: 2px;
    }
    50% {
        -webkit-transform: rotateZ(-65deg);
        transform: rotateZ(-65deg);
        right: 0;
    }
    68% {
        -webkit-transform: rotateZ(-410deg);
        transform: rotateZ(-410deg);
        right: 27px;
        bottom: 13px;
    }
    95% {
        -webkit-transform: rotateZ(-410deg);
        transform: rotateZ(-410deg);
        right: 0;
        bottom: 0;
    }
    100% {
        -webkit-transform: rotateZ(-360deg);
        transform: rotateZ(-360deg);
        right: 0;
        bottom: 0;
    }
}

@keyframes particles1 {
    0% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(35deg) translateY(0px);
        transform: rotateZ(35deg) translateY(0px);
    }
    63% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(35deg) translateY(0px);
        transform: rotateZ(35deg) translateY(0px);
    }
    64% {
        background-color: rgba(51, 51, 51, 1);
        -webkit-transform: rotateZ(35deg) translateY(0px);
        transform: rotateZ(35deg) translateY(0px);
    }
    100% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(35deg) translateY(-30px);
        transform: rotateZ(35deg) translateY(-30px);
    }
}

@keyframes particles2 {
    0% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-65deg) translateY(0px);
        transform: rotateZ(-65deg) translateY(0px);
    }
    63% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-65deg) translateY(0px);
        transform: rotateZ(-65deg) translateY(0px);
    }
    64% {
        background-color: rgba(51, 51, 51, 1);
        -webkit-transform: rotateZ(-65deg) translateY(0px);
        transform: rotateZ(-65deg) translateY(0px);
    }
    95% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-65deg) translateY(-40px);
        transform: rotateZ(-65deg) translateY(-40px);
    }
    100% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-65deg) translateY(-40px);
        transform: rotateZ(-65deg) translateY(-40px);
    }
}

@keyframes particles3 {
    0% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-75deg) translateY(0px);
        transform: rotateZ(-75deg) translateY(0px);
    }
    63% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-75deg) translateY(0px);
        transform: rotateZ(-75deg) translateY(0px);
    }
    64% {
        background-color: rgba(51, 51, 51, 1);
        -webkit-transform: rotateZ(-75deg) translateY(0px);
        transform: rotateZ(-75deg) translateY(0px);
    }
    97% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-75deg) translateY(-35px);
        transform: rotateZ(-75deg) translateY(-35px);
    }
    100% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-75deg) translateY(-35px);
        transform: rotateZ(-75deg) translateY(-35px);
    }
}

@keyframes particles4 {
    0% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-25deg) translateY(0px);
        transform: rotateZ(-25deg) translateY(0px);
    }
    63% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-25deg) translateY(0px);
        transform: rotateZ(-25deg) translateY(0px);
    }
    64% {
        background-color: rgba(51, 51, 51, 1);
        -webkit-transform: rotateZ(-25deg) translateY(0px);
        transform: rotateZ(-25deg) translateY(0px);
    }
    97% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-25deg) translateY(-30px);
        transform: rotateZ(-25deg) translateY(-30px);
    }
    100% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(-25deg) translateY(-30px);
        transform: rotateZ(-25deg) translateY(-30px);
    }
}

@keyframes particles5 {
    0% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(65deg) translateY(0px);
        transform: rotateZ(65deg) translateY(0px);
    }
    63% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(65deg) translateY(0px);
        transform: rotateZ(65deg) translateY(0px);
    }
    64% {
        background-color: rgba(51, 51, 51, 1);
        -webkit-transform: rotateZ(65deg) translateY(0px);
        transform: rotateZ(65deg) translateY(0px);
    }
    97% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(65deg) translateY(-35px);
        transform: rotateZ(65deg) translateY(-35px);
    }
    100% {
        background-color: rgba(51, 51, 51, 0);
        -webkit-transform: rotateZ(65deg) translateY(-35px);
        transform: rotateZ(65deg) translateY(-35px);
    }
}

@media only screen and (max-width: 1100px) {
    .contenido-generador {
        grid-template-columns: 1fr;
    }
    .grid-secciones>div {
        max-height: 200px;
        overflow-y: auto;
    }
    .grid-secciones {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 700px) {
    .grid-secciones {
        grid-template-columns: 1fr;
    }
    h1 {
        color: var(--azul-oscuro);
        font-weight: 900;
        font-size: 22px;
    }
    h2 {
        color: var(--azul-oscuro);
        font-size: 20px;
    }
    .secciones-container h2 {
        text-align: center;
    }
    h3 {
        color: var(--azul-oscuro);
    }
    p {
        font-size: 15px;
        color: #878787;
    }
    .login form {
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        padding: 30px;
        border-radius: 5px;
        width: 300px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 40px;
    }
}

@media only screen and (max-width: 500px) {
    .login form {
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        padding: 30px;
        border-radius: 5px;
        width: 250px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 40px;
    }
}